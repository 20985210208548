<template>
  <div class="zone">
    <TJDetailTitle :titleData="'销量统计_日成交量'" />
    <functionBox 
      :totalText="'总销售额：'+ $tool.moneyFormatter(Number(sumRMB), 2)+'元'"
      :titleData="{permission_flag:'Sales',nowID:4}"
      :isExport="true"
      :searhLoading="loading"
      :exportLoading="exportLoading"
      @searchBtn="searchBtn"
      @exportFun="export_excel_day_daily_tradeFun"
    >
    <template>
      <li>
        <el-date-picker
            ref="datedate"
            size="small"
            v-model="dateMonthValue"
            :format="format"
            :value-format="formatValue"
            :type="type"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
            class="Calendar"
          />
      </li>
    </template>
  </functionBox>   
    <!-- <div class="indent">
      <div class="indentone">
        <div class="left cursor" @click="toSales">省份</div>
        <div class="line"></div>
        <div class="spital cursor" @click="toAgency">经销商</div>
        <div class="line"></div>
        <div class="Volume cursor" @click="toVolume">报告</div>
        <div class="line"></div>
        <div class="Device cursor">日成交量</div>
        <p class="NumCla"> 总销售额：{{sumRMB}}元</p>
      </div>
      <div class="below">
        <div class="calendar">
          <el-date-picker
            ref="datedate"
            size="small"
            v-model="dateMonthValue"
            :format="format"
            :value-format="formatValue"
            :type="type"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
            class="Calendar"
          />
        </div>
        <div class="seachone" >
          <el-button type="primary" size="small" @click="searchBtn">
            查询
          </el-button>
          <el-button
            type="success"
            size="small"
            @click="export_excel_day_daily_tradeFun"
          >
            导出
          </el-button>
        </div>
      </div>
    </div> -->
    <div class="sheet">
      <el-table :data="tableData" stripe height="530" v-loading="loading">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="order_code"
          label="订单编号"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customer_name"
          label="经销商"
          show-overflow-tooltip
          align="center"
          width="250"
        ></el-table-column>
        <el-table-column
          prop="reagent_class_id"
          label="存货名称"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="format"
          label="产品规格"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orders_num"
          label="销售订单数(盒)"
          align="center"
        ></el-table-column>
        <el-table-column label="销售产品单价(元)" align="center">
          <template slot-scope="scope">
            {{ $tool.moneyFormatter(Number(scope.row.unit_price), 2) }}
          </template>
        </el-table-column>
        <el-table-column label="含税合计价格(元)" align="center">
          <template slot-scope="scope">
            {{ $tool.moneyFormatter(Number(scope.row.orders_sum), 2) }}
          </template>
        </el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import TJDetailTitle from '@/components/TJDetailTitle'
import functionBox from './components/functionBox.vue'

export default {
  components: { TJDetailTitle,functionBox },
  name: 'Turnover',
  data() {
    return {
      radio: '日',
      dateMonthValue: '', //日期

      page: 1,
      size: 10,
      total: 0,
      exportLoading: false,
      loading: false, //加载动画
      tableData: [],
      sumRMB: 0,//总金额
    }
  },
  computed: {
    type() {
      const arr = {
        年: 'year',
        月: 'monthrange',
        日: 'daterange',
      }
      return arr[this.radio]
    },
    startPlaceholder() {
      const arr = {
        年: '开始年份',
        月: '开始月份',
        日: '开始日期',
      }
      return arr[this.radio]
    },
    endPlaceholder() {
      const arr = {
        年: '结束年份',
        月: '结束月份',
        日: '结束日期',
      }
      return arr[this.radio]
    },
    format() {
      const arr = {
        年: 'yyyy',
        月: 'yyyy/MM',
        日: 'yyyy/MM/dd',
      }
      return arr[this.radio]
    },
    formatValue() {
      const arr = {
        年: 'yyyy',
        月: 'yyyyMM',
        日: 'yyyyMMdd',
      }
      return arr[this.radio]
    },
  },
  watch: {
    type() {
      this.start_stats_year = ''
      this.end_stats_year = ''
      this.dateMonthValue = ''
    },
  },
  mounted() {
    this.day_daily_trade_listFun()
  },
  methods: {
    day_daily_trade_listFun() {
      this.loading = true
      let param = {
        start_stats_day: this.dateMonthValue[0], //开始日期 例如:20210304
        end_stats_day: this.dateMonthValue[1], //结束日期
        page: this.page,
        size: this.size,
      }
      api.day_daily_trade_list(param).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.count
        this.loading = false
        this.sumRMB = res.data.order_total;
      }).catch(err=>{
        this.loading = false
      })
    },

    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },

    // 查询
    searchBtn() {
      this.page = 1
      // if (
      //   this.radio == '年' &&
      //   (this.start_stats_year == '' ||
      //     this.end_stats_year == '' ||
      //     this.start_stats_year == null ||
      //     this.end_stats_year == null)
      // ) {
      //   this.$message.warning('请选择开始年份及结束年份')
      //   return
      // }
      this.day_daily_trade_listFun()
    },

    // 翻页
    handleCurrentChange(val) {
      this.page = val
      this.day_daily_trade_listFun()
    },
    // 导出
    export_excel_day_daily_tradeFun() {
      this.exportLoading = true;
      let param = {
        start_stats_day: this.dateMonthValue[0], //开始日期 例如:20210304
        end_stats_day: this.dateMonthValue[1], //结束日期
        district_id: this.district_id,
        customer_id: this.customer_id,
      }
      api.export_excel_day_daily_trade(param).then((res) => {
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = 'imgName'
        a.href = res.data.file_url
        a.dispatchEvent(event)
      }).finally(msg => {
        this.exportLoading = false
      })
    },

    toVolume() {
      this.$router.push({ path: '/Volume' })
    },
    toSales() {
      this.$router.push({ path: '/Sales' })
    },
    toAgency() {
      this.$router.push({ path: '/Agency' })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor .el-range__icon {
  display: none;
}

.indent {
  height: px(121);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
}

.indentone {
  width: 100%;
  height: px(56);
  display: flex;
  border-bottom: 1px solid #d0d7e0;
  margin-bottom: px(15);
  .NumCla{
      justify-content: end;
      width: 70%;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #111111;
      margin-right: px(20);
    }
  .cursor {
    cursor: pointer;
  }
}
.left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.spital {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.Volume {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}
.Device {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
  margin-right: px(20);
}

.line {
  width: 1px;
  height: 13px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: px(16);
  margin-right: px(20);
}

.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.below {
  //height: px(36);
  display: flex;
}
::v-deep .el-input--small .el-input__inner {
  width: px(220);
}
.calendar {
  margin-left: px(10);
}
.cityProvince {
  margin-left: px(10);
}
::v-deep .cityProvince .el-input--small .el-input__inner {
  width: px(220);
}
.calendarone {
  display: flex;
  margin-left: px(10);
}
.seachone {
  margin-left: px(10);
}
.sheet {
  border-radius: px(10);
  background-color: #fff;
  margin-bottom: px(23);
}
.series {
  display: flex;
  margin-left: -12px;
}
.series img {
  width: px(36);
  height: px(36);
}
.bd8 {
  width: 1px;
  height: 8px;
  border-radius: 0.5px 0.5px 0.5px 0.5px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: 10px;
}
.combination {
  font-size: px(16);
  color: #333333;
  margin-top: px(10);
}
::v-deep .el-table__footer-wrapper .cell {
  font-size: px(14);
  font-weight: bold;
  color: #006ed0ff;
}
</style>
